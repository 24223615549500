<div class="LoginArea" [class]="currentLang" dir="ltr">
  <div [@fadeInOut] class="LoginBox">
    <div class="Logo">
      <img src="assets/images/Logo.png" />
    </div>
    <h3>{{ "Admin Login" | translate }}</h3>
    <p>
      {{ "Provide your email and password to take control" | translate }}
    </p>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>{{ "Email" | translate }}</label>
        <input
          type="text"
          id="email"
          class="form-control"
          placeholder="admin@rushrooms.com"
          formControlName="email"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div class="text-end h6" *ngIf="f.email.errors.required">
            {{ "Email is required" | translate }}
          </div>
          <div class="text-end h6" *ngIf="f.email.errors.email">
            {{ "Email must be a valid email address" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ "Password" | translate }}</label>
        <input
          [type]="showPass ? 'text' : 'password'"
          class="form-control"
          formControlName="password"
          placeholder="* * * * * * * * * *"
          autocomplete="new-password"
          aria-label="Password"
          aria-describedby="password-addon"
          autocomplete="off"
          [ngClass]="{
            'is-invalid': submitted && f.password.errors
          }"
        />
        <span class="Icon" (click)="togglePass()" role="button"
          ><i class="fa fa-eye"></i
        ></span>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div class="text-end h6">
            {{ "Password is required" | translate }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="mt-4 text-end">
          <a
            routerLink="/account/reset-password"
            class="text-muted forgot-pass-text"
            ><i class="mdi mdi-lock mr-1"></i>
            {{ "Forgot password?" | translate }}</a
          >
        </div>
      </div>

      <button class="Button w-100" type="submit">
        {{ "Login" | translate }}
      </button>
    </form>
  </div>

  <div class="LoginImage">
    <img src="assets/images/Building.png" />
  </div>
</div>
