import { trigger, transition, style, animate } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { FormManage } from "src/app/core/helpers/formVaild";
import { AuthenticationService } from "src/app/core/services/auth/auth.service";
import { FirebaseMessagesService } from "src/app/core/services/firebaseMessages/firebase-messages.service";
import { LanguageService } from "src/app/core/services/language/language.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("300ms", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class LoginComponent extends FormManage implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  currentLang;
  showPass: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private messagingService: FirebaseMessagesService
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.currentLang = this.languageService.currentLang;
  }

  initForm() {
    this.messagingService.requestPermission();

    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", Validators.required),
    });
    this.setForm(this.loginForm);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  togglePass() {
    this.showPass = !this.showPass;
  }

  onSubmit() {
    this.submitted = true;

    if (this.isFormValid) {
      this.authenticationService
        .Login(this.loginForm.value)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            this.authenticationService.User = res.data;

            if ("returnUrl" in this.route.snapshot.queryParams) {
              const returnURL = decodeURIComponent(
                this.route.snapshot.queryParams.returnUrl
              );
              const parsedURL = this.parseURL(returnURL);
              this.router.navigate([parsedURL.mainRoute], {
                queryParams: parsedURL.queryParams,
              });
            } else {
              this.router.navigate(["/home"]);
            }
          },
          error: (err) => (this.submitted = false),
        });
    }
  }

  parseURL(url) {
    const urlParts = url.split("?");
    const mainRoute = urlParts[0];

    if (urlParts.length > 1) {
      const queryParams = urlParts[1].split("&").reduce((params, param) => {
        const [key, value] = param.split("=");
        params[key] = value;
        return params;
      }, {});

      return { mainRoute, queryParams };
    }

    return { mainRoute, queryParams: {} };
  }
}
