import { HttpClientService } from "../http/http.service";
import { IPaymentModel } from "./../../models/payment.model";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  constructor(private http: HttpClientService) {}

  getAllPaymentData(IPaymentModelData: IPaymentModel) {
    return this.http.get("settings/payments", IPaymentModelData);
  }

  getPaymentData(paymentId: number) {
    return this.http.get(`settings/payments/${paymentId}`);
  }
  createPaymentMethod(body) {
    return this.http.postFormData(body, "settings/payments/create");
  }
  updatePaymentMethod(id, body) {
    return this.http.postFormData(body, `settings/payments/${id}/edit`);
  }
  deletePaymentMethod(id: number) {
    return this.http.post({}, `settings/payments/${id}/delete`);
  }
  paymentActivation(id) {
    return this.http.post({}, `settings/payments/${id}/activation`);
  }
}
