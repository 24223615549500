import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { User } from "../../models/auth.models";
import { HttpClientService } from "../http/http.service";
import { CachingService } from "../caching/caching.service";
import { FirebaseMessagesService } from "../firebaseMessages/firebase-messages.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public redirectUrl: string;
  public asCompany: number;
  public expiredTokenSubject: Subject<boolean>;

  constructor(
    private http: HttpClientService,
    private router: Router,
    private cachingService: CachingService,
    private messagingService: FirebaseMessagesService
  ) {
    this.expiredTokenSubject = new Subject();
  }

  public set User(user) {
    user.firebase_token = this.messagingService.firebaseToken
      ? this.messagingService.firebaseToken
      : "Not Allowed";

    this.cachingService.set("user", user);
  }

  public get User(): User {
    return this.cachingService.get("user") as User;
  }

  public get Token() {
    if (this.User) {
      return this.User.token;
    }
  }

  public get isLoggedIn() {
    if (!this.User) {
      return false;
    }

    return true;
  }

  public get getAdmin() {
    if (this.User && this.User.type === "admin") {
      return "admin";
    }
  }

  public HasPermission(permission) {
    if (this.isAdmin() || this.User.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  }

  public isAdmin() {
    if (this.User && this.User.type === "admin") {
      return true;
    }

    return false;
  }

  public logout() {
    this.removeUser();
    // this.http.post({}, "auth/logout").subscribe((_) => this.removeUser());
  }

  removeUser() {
    this.cachingService.remove("user");

    this.router.navigate(["account/login"]);
  }

  Login(body: { email: string; password: string }) {
    return this.http.post(body, "admin/login");
  }

  // Reset Password

  sendMailToReset(body) {
    return this.http.post(body, "admin/forget-password");
  }

  otpConfirmation(body) {
    return this.http.post(body, "admin/confirm-code");
  }

  resetPassword(body) {
    return this.http.post(body, "admin/reset-password");
  }
}
