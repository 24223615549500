import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
  NgbAlertModule,
} from "@ng-bootstrap/ng-bootstrap";

import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { RouterModule } from "@angular/router";
import { CutStringsPipe } from "src/app/core/pipes/cut-strings.pipe";
import { CurrencyPipe } from "src/app/core/pipes/currency.pipe";
import { FiltersComponent } from "./filters/filters.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { FieldErrorModule } from "./field-error/field-error.module";
import {GeneralSearchComponent} from './general-search/general-search.component';
import {ImageLoaderComponent} from './image-loader/image-loader.component';
@NgModule({
  declarations: [
    PagetitleComponent,
    CutStringsPipe,
    GeneralSearchComponent,
    CurrencyPipe,
    FiltersComponent,    ImageLoaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    NgSelectModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbAlertModule,
    ReactiveFormsModule,
    FieldErrorModule,
  ],
  exports: [PagetitleComponent, CutStringsPipe, CurrencyPipe, FiltersComponent,
    GeneralSearchComponent,    ImageLoaderComponent,
  ],
})
export class UIModule {}
