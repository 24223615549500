import { Injectable } from "@angular/core";
import { InotificationData } from "../../models/notification";
import { HttpClientService } from "../http/http.service";

@Injectable({
  providedIn: "root",
})
export class AdminNotificationService {
  constructor(private http: HttpClientService) {}

  getAllNotifications(InotificationData: InotificationData) {
    return this.http.get("notifications", InotificationData);
  }
  sendNotification(body) {
    return this.http.postFormData(body, "notifications/send");
  }

  public getAllNotificationsPaginate(page?: number) {
    return this.http.get("notifications", { page });
  }

  public markAsRead(notificationId: string) {
    return this.http.post({ id: notificationId }, "notifications/mark-as-read");
  }

  public markAllAsRead() {
    return this.http.post({}, "notifications");
  }

  public totalUnread() {
    return this.http.get("notifications/total-unread-notifications");
  }
}
