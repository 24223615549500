<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 text-center">{{ year }} © Rush Rooms.</div>
      <!-- <div class="col-sm-6">
         // removed by client :( request
        <div style="display: none" class="text-sm-end d-none d-sm-block">
          Design & Develop by Digital Creativity
        </div>
       
      </div> -->
    </div>
  </div>
</footer>
