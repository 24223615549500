import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
} from "@angular/core";
import MetisMenu from "metismenujs/dist/metismenujs";
import { Router, NavigationEnd } from "@angular/router";

import { HttpClient } from "@angular/common/http";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/core/services/auth/auth.service";
import { EventService } from "src/app/core/services/event/event.service";
import { SidebarService } from "src/app/core/services/sidebar/sidebar.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("componentRef") scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  searchText = "";
  isAdmin = false;
  menuItems = [];
  updatedMenuItem = [];
  sidebarCounts;
  @ViewChild("sideMenu") sideMenu: ElementRef;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private sidebarService: SidebarService,
    private authService: AuthenticationService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
  }

  ngOnInit() {
    this.isAdmin = this.authService.isAdmin();

    this.initialize();
    this._scrollElement();
    this.checkPermissions();
    this.getSidebarCounts();
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement, {
      toggle: false, // Prevent auto-collapsing when clicking on other items
    });
    this._activateMenuDropdown();
    this._expandAllMenus();
  }

  clearSearch() {
    this.searchText = "";
  }

  toggleMenu(event) {
    event.preventDefault(); // Prevent default href behavior
    const submenu = event.currentTarget.nextElementSibling;
    if (submenu) {
      const isSubMenuOpen = submenu.classList.contains("mm-show");

      // If the submenu is already open and the user clicks again, we should avoid toggling it back.
      if (isSubMenuOpen) {
        return;
      }

      // Close all other open submenus
      const allSubMenus = this.sideMenu.nativeElement.querySelectorAll('.sub-menu.mm-show');
      allSubMenus.forEach((sm) => {
        if (sm !== submenu) {
          sm.classList.remove("mm-show");
          const parentLi = sm.closest("li");
          if (parentLi) {
            parentLi.classList.remove("mm-active");
          }
        }
      });

      // Open the clicked submenu
      submenu.classList.add("mm-show");
      event.currentTarget.parentElement.classList.add("mm-active");
    }
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement, {
          toggle: false,
        });
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0]["offsetTop"];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass("mm-active");
    this._removeAllClass("mm-show");
    const links = document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add("active");
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add("mm-active");
        const parent2El = parentEl.parentElement.closest("ul");
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== "side-menu") {
            parent3El.classList.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");
            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== "side-menu") {
              parent4El.classList.add("mm-show");
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== "side-menu") {
                parent5El.classList.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Expands all menus by adding mm-show to all sub-menus
   */
  _expandAllMenus() {
    const subMenus = this.sideMenu.nativeElement.querySelectorAll('.sub-menu');
    subMenus.forEach((submenu) => {
      submenu.classList.add('mm-show');
    });
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems && item.subItems.length > 0;
  }
  

  checkPermissions() {
    this.updatedMenuItem = [];
    if (this.authService.isAdmin()) {
      this.updatedMenuItem = this.menuItems;
    } else {



      this.menuItems.forEach((element) => {
        const userHasParentPermission = this.authService.User.permissions.includes(element.permission);
        let permittedSubItems = [];

        // Check if the element has subItems
        if (element.subItems && element.subItems.length > 0) {
          permittedSubItems = element.subItems.filter((subItem) =>
              this.authService.User.permissions.includes(subItem.permission)
          );
        }

        // Include the parent item if the user has permission or if there are permitted subItems
        if (userHasParentPermission || permittedSubItems.length > 0) {
          const menuItem = { ...element };

          // If there are permitted subItems, assign them to the menuItem
          if (permittedSubItems.length > 0) {
            menuItem.subItems = permittedSubItems;
          } else {
            delete menuItem.subItems;
          }

          this.updatedMenuItem.push(menuItem);
        }
      });
    }
  }

  getSidebarCounts() {
    this.sidebarService
      .getSidebarCounts()
      .pipe(take(1))
      .subscribe((res) => {
        // Ensure the data from the backend is assigned correctly
        this.sidebarCounts = res.data;
  
        // Ensure the sidebarCounts contains the necessary fields
        this.sidebarCounts = {
          pending_reservations: res.data.pending_reservations || 0,
          payouts_requests: res.data.payouts_requests || 0,
          verification_requests: res.data.verification_requests || 0,
          refund_requests: res.data.refund_requests || 0,
          new_properties: res.data.new_properties || 0,
        };
      });
  }
  
}
