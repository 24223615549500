<form [formGroup]="filterForm" (ngSubmit)="Submit()">
  <div class="row">
    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.username">
      <label for="user_name"> {{ "User Name" | translate }} </label>

      <input
        id="username"
        name="username"
        type="text"
        class="form-control"
        formControlName="username"
        [placeholder]="'User Name' | translate"
      />
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.address">
      <label for="address"> {{ "Address" | translate }} </label>

      <input
        id="address"
        name="address"
        type="text"
        class="form-control"
        formControlName="address"
        [placeholder]="'Address' | translate"
      />
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.keyword">
      <label for="keyword"> {{ "Search" | translate }} </label>

      <input
        id="keyword"
        name="keyword"
        type="text"
        class="form-control"
        formControlName="keyword"
        [placeholder]="'Search' | translate"
      />
    </div>
    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.reservation_id">
      <label for="reservation_id"> {{ "Reservation ID" | translate }} </label>

      <input
        id="reservation_id"
        name="reservation_id"
        type="text"
        class="form-control"
        formControlName="reservation_id"
        [placeholder]="'Reservation ID' | translate"
      />
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.email">
      <label for="email"> {{ "Email" | translate }} </label>

      <input
        id="email"
        name="email"
        type="text"
        class="form-control"
        formControlName="email"
        [placeholder]="'Email' | translate"
      />
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.phone">
      <label for="phone"> {{ "Phone" | translate }} </label>

      <input
        id="phone"
        name="phone"
        type="text"
        class="form-control"
        [placeholder]="'Phone' | translate"
        formControlName="phone"
      />
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.referral_code">
      <label for="referral_code"> {{ "Referral Code" | translate }} </label>

      <input
        id="referral_code"
        name="referral_code"
        type="text"
        class="form-control"
        [placeholder]="'Referral Code' | translate"
        formControlName="referral_code"
      />
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.from">
      <label for="from"> {{ "From" | translate }} </label>
      <input
        type="date"
        id="from"
        name="from"
        class="form-control"
        [placeholder]="'From' | translate"
        formControlName="from"
      />
    </div>
    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.to">
      <label for="to"> {{ "To" | translate }} </label>

      <input
        type="date"
        id="to"
        name="to"
        class="form-control"
        [placeholder]="'To' | translate"
        formControlName="to"
      />
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.gender">
      <label for="gender"> {{ "Gender" | translate }} </label>

      <ng-select formControlName="gender" [placeholder]="'Gender' | translate">
        <ng-option value="male">{{ "Male" | translate }}</ng-option>
        <ng-option value="female">{{ "Female" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.most_reserved">
      <label for="most_reserved"> {{ "Reservations" | translate }} </label>

      <ng-select
        formControlName="most_reserved"
        [placeholder]="'Reservations' | translate"
      >
        <ng-option value="ASC">{{
          "The Highest Reservation" | translate
        }}</ng-option>
        <ng-option value="DESC">{{
          "The Least Reservation" | translate
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.best_seller">
      <label for="best_seller"> {{ "Total Earning" | translate }} </label>

      <ng-select
        formControlName="best_seller"
        [placeholder]="'Total Earning' | translate"
      >
        <ng-option value="ASC">{{
          "The Highest Earning" | translate
        }}</ng-option>
        <ng-option value="DESC">{{
          "The Least Earning" | translate
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.is_active">
      <label for="is_active"> {{ "Status" | translate }} </label>

      <ng-select
        formControlName="is_active"
        [placeholder]="'Status' | translate"
      >
        <ng-option [value]="1">{{ "Active" | translate }}</ng-option>
        <ng-option [value]="0">{{ "Inactive" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.is_refunded">
      <label for="is_refunded"> {{ "Refund Status" | translate }} </label>

      <ng-select
        formControlName="is_refunded"
        [placeholder]="'Refund Status' | translate"
      >
        <ng-option [value]="1">{{ "Refunded" | translate }}</ng-option>
        <ng-option [value]="0">{{ "Not Refunded Yet" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.type">
      <label for="type"> {{ "Type" | translate }} </label>

      <ng-select formControlName="type" [placeholder]="'Type' | translate">
        <ng-option [value]="'add_wallet'">{{
          "Charge Wallet" | translate
        }}</ng-option>
        <ng-option [value]="'refund'">{{ "Refund" | translate }}</ng-option>
        <ng-option [value]="'booking'">{{
          "Reservations" | translate
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.status">
      <label for="status"> {{ "Status" | translate }} </label>

      <ng-select formControlName="status" [placeholder]="'Status' | translate">
        <ng-option [value]="1">{{ "Available" | translate }}</ng-option>
        <ng-option [value]="3">{{ "Busy" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.country_id">
      <label for="country_id"> {{ "Country" | translate }} </label>

      <ng-select
        formControlName="country_id"
        [placeholder]="'Country' | translate"
        (keydown.enter)="countriesSearch($event.target.value)"
      >
        <ng-option *ngFor="let country of countries" [value]="country?.id">{{
          country?.name
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.city_id">
      <label for="city_id"> {{ "City" | translate }} </label>

      <ng-select
        formControlName="city_id"
        [placeholder]="'City' | translate"
        (keydown.enter)="citySearch($event.target.value)"
      >
        <ng-option *ngFor="let city of cities" [value]="city?.id">{{
          city?.name
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.payment_id">
      <label for="payment_id"> {{ "Payment Method" | translate }} </label>

      <ng-select
        formControlName="payment_id"
        [placeholder]="'Payment Method' | translate"
      >
        <ng-option
          *ngFor="let method of payment_methods"
          [value]="method?.id"
          >{{ method?.name }}</ng-option
        >
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.user_id">
      <label for="user_id"> {{ "Users" | translate }} </label>

      <ng-select
        formControlName="user_id"
        [placeholder]="'Users' | translate"
        (keydown.enter)="usersSearch($event.target.value)"
      >
        <ng-option *ngFor="let user of users" [value]="user?.id">{{
          user?.first_name + " " + user?.last_name
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.owner_id">
      <label for="owner_id"> {{ "Products Owners" | translate }} </label>

      <ng-select
        formControlName="owner_id"
        [placeholder]="'Products Owners' | translate"
        (keydown.enter)="ownersSearch($event.target.value)"
      >
        <ng-option *ngFor="let user of owners" [value]="user?.id">{{
          user?.first_name + " " + user?.last_name
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.property_id">
      <label for="property_id"> {{ "Products" | translate }} </label>

      <ng-select
        formControlName="property_id"
        [placeholder]="'Products' | translate"
        (keydown.enter)="propertySearch($event.target.value)"
      >
        <ng-option *ngFor="let prop of properties" [value]="prop?.id">{{
          prop?.title
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.sort_by_id">
      <label for="sort_by_id"> {{ "Arrangement" | translate }} </label>

      <ng-select
        formControlName="sort_by_id"
        [placeholder]="'Arrangement' | translate"
      >
        <ng-option value="DESC">{{
          "From Newest To Oldest" | translate
        }}</ng-option>
        <ng-option value="ASC">{{
          "From Oldest To Newest" | translate
        }}</ng-option>
      </ng-select>
    </div>

    <div class="col-md-2 mb-2" *ngIf="this.filterForm.controls.admin_approval">
      <label for="admin_approval">
        {{ "Activation Status Of Products" | translate }}
      </label>

      <ng-select
        formControlName="admin_approval"
        [placeholder]="'Activation Status Of Products' | translate"
      >
        <ng-option [value]="1">{{ "Active" | translate }}</ng-option>
        <ng-option [value]="0">{{ "Inactive" | translate }}</ng-option>
      </ng-select>
    </div>

    <div class="col-4 d-flex align-items-end">
      <button
        type="submit"
        *ngIf="showActions"
        class="btn btn-primary w-sm ms-2 mb-2"
      >
        {{ "Search" | translate }}
      </button>

      <button
        type="button"
        class="btn btn-danger w-sm ms-2 mb-2"
        *ngIf="showActions"
        (click)="reset()"
      >
        {{ "Reset" | translate }}
      </button>
    </div>
  </div>
</form>
