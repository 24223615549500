import { Injectable } from "@angular/core";
import { HttpClientService } from "../http/http.service";
import { BehaviorSubject } from "rxjs";
import { IpropertyData } from "../../models/property.model";

@Injectable({
  providedIn: "root",
})
export class PropertiesService {
  public propDetailsLoaded = new BehaviorSubject<any>(null);
  public pricesDetailsLoaded = new BehaviorSubject<any>(null);

  properties = [];

  constructor(private http: HttpClientService) {}

  getAllProperties(options: IpropertyData) {
    return this.http.get("properties", options);
  }

  getPropertyById(id: number) {
    return this.http.get(`properties/${id}`);
  }

  createProperty(body) {
    return this.http.postFormData(body, "properties/create");
  }
  updateProperty(body, id) {
    return this.http.postFormData(body, `properties/${id}/edit`);
  }
  deleteProperty(id) {
    return this.http.post({}, `properties/${id}/delete`);
  }

  deletePropertyMedia(id) {
    return this.http.post({}, `properties/${id}/media-delete`);
  }

  setPropertyPrice(body, id) {
    return this.http.post(body, `properties/${id}/set-prices`);
  }
  updatePropertyPrice(body, id) {
    return this.http.post(body, `properties/${id}/edit-prices`);
  }

  getAllPropertyRates(id: number) {
    return this.http.get(`properties/${id}/calendar`, { default: 1 });
  }

  setPropertyDefaultPriceRate(propID, rateId) {
    return this.http.post(
      {},
      `properties/${propID}/${rateId}/set-search-default`
    );
  }

  getProrepertyCalenderDetails(id) {
    return this.http.get(`properties/${id}/all`);
  }

  updatePropertyStatus(id) {
    return this.http.post({}, `properties/${id}/approval`);
  }

  PropertiesReorder(body) {
    return this.http.post(body, `properties/reorder`);
  }
}
